import { Api } from "./base";
const _headers = () => {
  return {
    Authorization:
      "JWT " + require("store").default.getState().onboard.user.auth_token,
    "Content-Type": "application/json",
  };
};

export default {
  getAllParents(search) {
    if (search === "") return Api.get(`/user/v1/admin-parent/`, _headers());
    else return Api.getCsv(`/user/v1/admin-parent/?${search}`, _headers());
  },
  updateParentProfile(parentId, data) {
    return Api.put(`/user/v1/admin-parent/${parentId}/`, data, _headers());
  },
  getParentById(parentId) {
    return Api.get(`/user/v1/admin-parent/${parentId}/`, _headers());
  },
  getAllChildren(search,signal) {
    if (search === "") return Api.get(`/user/v1/admin-child/`, _headers());
    else return Api.getCsv(`/user/v1/admin-child/?${search}`, _headers(),signal);
  },
  updateChildProfile(childId, data) {
    return Api.put(`/user/v1/admin-child/${childId}/`, data, _headers());
  },
  getChildById(childId) {
    return Api.get(`/user/v1/admin-child/${childId}/`, _headers());
  },
  getChildrenByParentId(parentId) {
    return Api.get(`/user/v1/admin-child/?parent=${parentId}`, _headers());
  },
  exportData(keyValue, searchText) {
    let url = `/misc/v1/admin-export-to-csv/?csv=${keyValue.userType}`;

    if (searchText.length > 0) url = url + `&${searchText}`;
    if (keyValue.startedAt) url = url + `&started_at=${keyValue.startedAt}`;
    if (keyValue.endedAt) url = url + `&ended_at=${keyValue.endedAt}`;
    if (keyValue.email) url = url + `&email=${keyValue.email}`;

    return Api.getCsv(url, _headers());
  },
  updateFreadosByChildId(data) {
    return Api.post("/misc/v1/award-freadom-point/", data, _headers());
  },
  updateStreakByChildId(data) {
    return Api.post("/misc/v1/award-streak/", data, _headers());
  },
  registerUser(data) {
    return Api.post("/user/v1/admin-create-user/", data, _headers());
  },
  validateSchool(code) {
    return Api.get("/school/v1/validate-school-code/?school_code=" + code);
  },
  getSections(data) {
    return Api.post("/misc/v1/grade-school-class/", data, _headers());
  },
  linkSchoolCode(data) {
    return Api.post("/school/v1/admin-link-school/", data, _headers());
  },
  unlinkSchoolCode(data) {
    return Api.post("/school/v1/admin-unlink-school/", data, _headers());
  },
  exportDataToCsv(search,type) {
    let url = `/misc/v1/admin-generate-external-csv/?csv=${type}`
    if (search === "")
      return Api.getRquestWithDownload(
       url,
        _headers()
      );
    else
      return Api.getRquestWithDownload(
        `${url}&${search}`,
        _headers()
      );
  },
  bulkUpdateGradeSection(data){
    return Api.post("/user/v1/admin-update-grade-section/",data,_headers());
  },
  getChildAcademicYears(){
    return Api.get("/misc/v1/child-academic-years/")
  },
  getChildBaselineStatus(school_code,grade,section){
    return Api.get(`/teacher/v1/iro-exam-status/?school_code=${school_code}&grade_name=${grade}&section_name=${section}`,_headers())
  }
};
