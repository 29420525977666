export default {
  schoolList: [],
  projectList: [],
  slotList: [],
  classList: [],
  sectionList: [],
  inviteCodeDetail: {},
  schoolCodes: [],
  schoolCodesOffset: 0,
  schoolCodesLength: 0,
  schoolSearchText: "",
  searchSchoolResult: [],
  selectedSchool: null,
  adminSchoolList: [],
  schoolListResult: null,
  fetch_school_list: false,
  schoolEdit: false,
  schoolEditInfo: null,
  classResultList: [],
  schoolSearch: null,
  selectedSearchOption: "name",
  reportData: {},
  schoolGrade: [],
  schoolGuidedPathFormData: null,
  allGrades: [],
  schoolIro: {},
  schoolIroFormData: null,
};
