import React, { Component } from "react";
import {
  ToggleButton,
  ToggleHandler,
  ToggleStatus,
  UserDetailValues,
  UserField2,
  UserFieldEmail,
  UserFieldName,
} from "../../../MainContent.styled";
import { module as user } from "@st/user";
import { connectStore } from "redux-box";
import Permission from "@s/permission";
import { module as onboard } from "@st/onboard";
import {
  ThemeButton,
  McqOptionsToolbar,
  CheckBox,
  McqOptionItem,
  PublishContainer,
} from "static/styled/custom.styled";
// export default connectStore({
//   user,
// })

class ChildCard extends Component {
  constructor(props) {
    super();
  }
  handleToggle = (e) => {
    let { user } = this.props;
    if (this.props.child)
      user.requestUpdateChildProfile({
        id: this.props.child.id,
        is_active: !this.props.child.is_active,
      });

    if (
      this.props.child.id &&
      this.props.child.school_code &&
      this.props.child.is_active
    ) {
      let data = {
        school: this.props.child.school_code,
        child: this.props.child.id,
        showMessage: false,
      };
      user.unlinkSchoolCode(data);
    }
    e.preventDefault();
    e.stopPropagation();
  };

  handleCheckboxSelected = (e) => {
    e.stopPropagation();
    if(this.props.user && this.props.child){
      if (e.target.checked) {
        this.props.user.setSelectedChildren([
          ...this.props.user.selectedChildren,
          this.props.child.id,
        ]);
      } else {
        this.props.user.setSelectedChildren(
          this.props.user.selectedChildren.filter(
            (id) => id !== this.props.child.id
          )
        );
      }
    }
  };

  render() {
    // let state=this.state;
    const { user } = this.props.onboard;
    const childInfo = this.props.child;
    const { filterChildSearch,selectedChildren } = this.props.user;
    return (
      <UserDetailValues>
        {filterChildSearch.grade__name__icontains &&
        filterChildSearch.grade__name__icontains !== "" &&
        ((filterChildSearch.school__iexact &&
          filterChildSearch.school__iexact !== "") ||
          user.school) &&
        filterChildSearch.section &&
        filterChildSearch.section !== "" ? (
          <input type={"checkbox"} checked={selectedChildren && selectedChildren.find((child)=>child===childInfo.id)?true:false} onClick={this.handleCheckboxSelected} />
        ) : null}
        <UserFieldName>{childInfo.name} </UserFieldName>
        <UserFieldName>{childInfo.school} </UserFieldName>
        <UserField2> {childInfo.grade.name}</UserField2>
        <UserField2> {childInfo.section && childInfo.section.name}</UserField2>
        <UserField2> {childInfo.academic_year && childInfo.academic_year.academic_year}</UserField2>
        {/* <UserFieldName> {childInfo.parent.name} </UserFieldName> */}

        {/* <UserField2> {childInfo.parent.contact_no} </UserField2> */}
        <UserFieldEmail primary align width="240px">
          {childInfo.is_active ? (
            <ToggleStatus fontSize="12px" primary onClick={this.handleToggle}>
              {" "}
              Disable{" "}
            </ToggleStatus>
          ) : (
            <ToggleStatus fontSize="12px" onClick={this.handleToggle}>
              {" "}
              Disabled
            </ToggleStatus>
          )}
          <ToggleButton
            style={{ margin: "0px 6px" }}
            height="22px"
            color={childInfo.is_active ? "#ff8f26" : "#d5d5d5"}
          >
            {!childInfo.is_active ? (
              <ToggleHandler
                primary
                height="18px"
                width="18px"
                onClick={this.handleToggle}
              />
            ) : (
              <ToggleHandler
                width="18px"
                height="18px"
                left="25px"
                onClick={this.handleToggle}
              />
            )}
          </ToggleButton>
          {!user.read_only && childInfo.is_active ? (
            <ToggleStatus fontSize="12px" onClick={this.handleToggle}>
              {" "}
              Enabled{" "}
            </ToggleStatus>
          ) : (
            !user.read_only && (
              <ToggleStatus fontSize="12px" primary onClick={this.handleToggle}>
                {" "}
                Enable
              </ToggleStatus>
            )
          )}
        </UserFieldEmail>
      </UserDetailValues>
    );
  }
}

// export default ChildCard;
export default connectStore({
  user, onboard
})(ChildCard)