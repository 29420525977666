import { Api } from "./base";
import axios from "axios";
import Config from "@/services/config/index.js";
// ..................wow authorization token for prod first letter is 1 and for dev 0
const _headers = () => {
  return {
    Authorization: Config.auth_token,
    "Content-Type": "application/json",
  };
};

const freadom_headers = () => {
  return {
    Authorization:
      "JWT " + require("store").default.getState().onboard.user.auth_token,
    "Content-Type": "application/json",
  };
};

export default {
  getSchoolList() {
    return Api.getList(`/school/`, _headers());
  },
  async getProjectList() {
    const username = "";
    const password = "live_api_key_GlokVRVF310cBbZbLs5GvxLTCMlDVlDL";

    const token = btoa(`${username}:${password}`);

    const res = await axios.get(`https://api.timekit.io/v2/projects`, {
      headers: {
        Authorization: `Basic ${token}`,
        "Content-Type": "application/json",
      },
    });

    console.log("Response::", res);
    return res;
  },
  async getSlotList() {
    const username = "";
    const password = "test_api_key_6uY8dMm0uyCxyBiNPCbyHaFwEvwVpiP0";

    const token = btoa(`${username}:${password}`);

    const res = await axios.get(`https://api.timekit.io/v2/bookings`, {
      headers: {
        Authorization: `Basic ${token}`,
        "Content-Type": "application/json",
      },
      params: {
        search: {
          graph: "graph_owner",
        },
      },
    });

    console.log("Response::", res);
    return res;
  },
  getClassList(schoolId) {
    return Api.getList(`/school/${schoolId}/class/`, _headers());
  },

  getSectionList(schoolId, classId) {
    return Api.getList(
      `/school/${schoolId}/class/${classId}/section/`,
      _headers()
    );
  },
  createRandomInviteCode(data) {
    return Api.postRequest(`/misc/codes/`, data, _headers());
  },
  updateSchoolCode(id, data) {
    return Api.put(
      `/misc/v1/admin-school-code/${id}/`,
      data,
      freadom_headers()
    );
  },
  verifyInviteCodeCsv(data) {
    return Api.get(
      `/misc/v1/verify-invite-code-csv/?code=${data}`,
      freadom_headers()
    );
  },
  getSchoolCodeList(queryParam) {
    return Api.getCsv(
      `/misc/v1/admin-school-code/?${queryParam}`,
      freadom_headers()
    );
  },
  getSchoolCodeListOnSearch(queryParam) {
    return Api.getCsv(
      `/misc/v1/admin-school-code/?${queryParam}`,
      freadom_headers()
    );
  },
  createSchoolCode(data) {
    return Api.post(`/misc/v1/admin-school-code/`, data, freadom_headers());
  },
  getAdminSchoolList(queryParam) {
    return Api.getRequest(
      `/school/v1/admin-school/?${queryParam}`,
      freadom_headers()
    );
  },
  createSchool(data) {
    return Api.post(`/school/v1/admin-school/`, data, freadom_headers());
  },
  updateSchool(id, data) {
    return Api.put(`/school/v1/admin-school/${id}/`, data, freadom_headers());
  },
  createClass(data) {
    return Api.post(`/school/v1/admin-school-class/`, data, freadom_headers());
  },
  getClass(queryParam) {
    return Api.get(
      `/school/v1/admin-school-class/?${queryParam}`,
      freadom_headers()
    );
  },
  getReport(queryParam) {
    return Api.getCmfluencyData(
      `/cmfluency/cmf_school_report?school_code=${queryParam}`,
      freadom_headers()
    );
  },
  getSchoolGrade(schoolCode) {
    return Api.get(
      `/school/v1/admin-school-class/${schoolCode}?fetch_school_garde=true`,
      freadom_headers()
    );
  },

  updateSchoolGuidedPath(data) {
    return Api.post(
      `/guided-path/v1/admin-school-guided-path/`,
      data,
      freadom_headers()
    );
  },

  getAllGrades() {
    return Api.get("/misc/v1/grade/", _headers());
  },

  getSchoolIro(school_id) {
    return Api.get(
      `/irotest/v1/admin-iro-school?school=${school_id}`,
      freadom_headers()
    );
  },

  updateSchoolIro(data) {
    console.log("data:", data);
    return Api.post(`/irotest/v1/admin-iro-school/`, data, freadom_headers());
  },
};
