import { createSagas } from "redux-box";
import userApi from "@s/api/user";
import GradeApi from "@s/api/grade";
import { all, call, put, takeLatest, take, race } from "redux-saga/effects";
//import {push} from "react-router-redux";
import swal from "sweetalert2";
import { getFormValues } from "redux-form";
import AbortController from "abort-controller";
import * as XLSX from "xlsx";

const errorHandler = (err) => {
  //console.log(err);
  swal("Oops!", err.error_message, "error");
};

const validate = (name) => {
  let n = name.trim();
  const re = /^[a-zA-Z0-9_ .]*$/;
  if (re.test(n) == true && name.length > 2) return true;
  return false;
};

const validateEmail = (email) => {
  // const re =
  //   // eslint-disable-next-line no-useless-escape
  //   /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/;
  const re =
    // eslint-disable-next-line no-useless-escape
    /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;

  return re.test(email);
};

const validatePassword = (password) => {
  const re = /^[0-9]+$/;
  // console.log(re.test(password),password.toString().length)
  if (re.test(password) == true && password.toString().length == 6) return true;
  return false;
};

const handleDownload = (data) => {
  let fileName = "errors";

  // Create a new workbook
  const workbook = XLSX.utils.book_new();

  // Convert data to a worksheet
  const worksheet = XLSX.utils.json_to_sheet(data);

  // Append the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Write the workbook to a binary string
  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });

  // Create a Blob from the binary string
  const blob = new Blob([excelBuffer], {
    type: "application/octet-stream",
  });

  // Create a link element
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${fileName}.xlsx`;

  // Programmatically click the link to trigger the download
  document.body.appendChild(a);
  a.click();

  // Clean up and remove the link
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

const sagas = createSagas({
  //SAGA TO GET PARENT LIST
  REQUEST_PARENTS: function*() {
    try {
      let search = "";
      const globalState = require("store").default.getState();
      let offset = globalState.user.parents_offset;
      let filterParent = globalState.user.filterParentSearch;
      let parent_search = globalState.user.search_parent;
      let val = 0;
      let count = 0;
      for (let i in filterParent) {
        if (filterParent[i] !== "") count++;
      }

      for (let i in filterParent) {
        if (filterParent[i] !== "") {
          if (count === 1) {
            search += i + "=" + filterParent[i];
            break;
          } else {
            search += i + "=" + filterParent[i];
            if (val < count - 1) search += "&";
            val++;
          }
        }
      }
      if (parent_search !== "") {
        if (count === 0) search += "key=" + parent_search;
        else search += "&key=" + parent_search;
      }
      yield put({
        type: "SET_FETCH_PARENT",
        value: true,
      });
      if (offset >= 0) {
        if (count > 0 || parent_search !== "") search += "&offset=" + offset;
        else search += "offset=" + offset;
      }

      const res = yield call(userApi.getAllParents, search);
      yield put({
        type: "SET_PARENTS",
        parents: res,
      });
    } catch (err) {
      errorHandler(err);
    } finally {
      yield put({
        type: "SET_FETCH_PARENT",
        value: false,
      });
    }
  },
  REQUEST_PARENTS_BY_OFFSET: function*({ offset }) {
    try {
      yield put({
        type: "SET_PARENTS_OFFSET",
        offset: offset,
      });
      yield put({
        type: "REQUEST_PARENTS",
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_PARENT_BY_ID: function*({ parentId }) {
    try {
      const res = yield call(userApi.getParentById, parentId);
      yield put({
        type: "SET_ACTIVE_PARENT",
        parent: res,
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  REQUEST_CHILDREN: function*() {
    const controller = new AbortController();
    const signal = controller.signal;
    try {
      let search = "";
      const globalState = require("store").default.getState();
      let offset = globalState.user.children_offset;
      let filterChild = globalState.user.filterChildSearch;
      let child_search = globalState.user.search_child;
      let val = 0;
      let count = 0;
      for (let i in filterChild) {
        if (filterChild[i] !== "") count++;
      }

      for (let i in filterChild) {
        if (filterChild[i] !== "") {
          if (count === 1) {
            search += i + "=" + filterChild[i];
            break;
          } else {
            search += i + "=" + filterChild[i];
            if (val < count - 1) search += "&";
            val++;
          }
        }
      }
      if (child_search !== "") {
        if (count === 0) search += "key=" + child_search;
        else search += "&key=" + child_search;
      }
      if (offset >= 0) {
        if (count > 0 || child_search !== "") search += "&offset=" + offset;
        else search += "offset=" + offset;
      }
      yield put({
        type: "SET_FETCH_CHILD",
        value: true,
      });
      // const res = yield call(userApi.getAllChildren, search,signal);
      const { response } = yield race({
        response: call(userApi.getAllChildren, search, signal), // Make API call with signal
        cancel: take("CANCEL_REQUEST_CHILDREN"), // Listen for cancellation action
      });
      // console.log(res);

      if (response) {
        yield put({
          type: "SET_CHILDREN",
          children: response,
        });
      } else {
        controller.abort();
      }
    } catch (err) {
      // errorHandler(err);
      if (err.name === "AbortError") {
        console.log("Fetch aborted");
      } else {
        errorHandler(err); // Handle other errors
      }
    } finally {
      yield put({
        type: "SET_FETCH_CHILD",
        value: false,
      });
    }
  },

  REQUEST_UPDATE_PARENT_PROFILE: function*({ parent }) {
    try {
      const res = yield call(userApi.updateParentProfile, parent.id, parent);
      if (res) {
        swal("Done!", res.name + " profile updated successfully!", "success");
      }
      yield put({
        type: "REQUEST_PARENT_BY_ID",
        parentId: res.id,
      });
      yield put({
        type: "REQUEST_PARENTS",
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_CHILDREN_BY_OFFSET: function*({ offset }) {
    try {
      yield put({
        type: "SET_CHILDREN_OFFSET",
        offset: offset,
      });
      yield put({
        type: "REQUEST_CHILDREN",
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  REQUEST_CHILDREN_BY_PARENT_ID: function*({ parentId }) {
    try {
      const res = yield call(userApi.getChildrenByParentId, parentId);
      yield put({
        type: "SET_CHILDREN",
        children: res,
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_CHILD_BY_ID: function*({ childId }) {
    try {
      const res = yield call(userApi.getChildById, childId);
      yield put({
        type: "SET_ACTIVE_CHILD",
        child: res,
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_UPDATE_CHILD_PROFILE: function*({ child }) {
    try {
      const res = yield call(userApi.updateChildProfile, child.id, child);
      if (res) {
        swal("Done", "Sub profile updated successfully.", "success");
        yield put({
          type: "SET_ACTIVE_CHILD",
          child: res,
        });
        yield put({
          type: "REQUEST_CHILD_BY_ID",
          childId: res.id,
        });
        yield put({
          type: "REQUEST_CHILDREN",
        });
      }
    } catch (err) {
      errorHandler(err);
    }
  },

  REQUEST_UPDATE_CHILD_SUB_LEVEL: function*({ child }) {
    try {
      const res = yield call(userApi.updateChildProfile, child.id, child);
      swal(
        "Done!",
        res.name + "'s level updated successfully to hard!",
        "success"
      );
      yield put({
        type: "REQUEST_CHILD_BY_ID",
        childId: res.id,
      });
      yield put({
        type: "REQUEST_CHILDREN",
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  EXPORT_PARENT_DATA: function*({ data }) {
    try {
      let search = "";
      const globalState = require("store").default.getState();
      //let offset = globalState.user.parents_offset;
      let filterParent = globalState.user.filterParentSearch;
      let parent_search = globalState.user.search_parent;
      let val = 0;
      let count = 0;
      for (let i in filterParent) {
        if (filterParent[i] !== "") count++;
      }

      for (let i in filterParent) {
        if (filterParent[i] !== "") {
          if (count === 1) {
            search += i + "=" + filterParent[i];
            break;
          } else {
            search += i + "=" + filterParent[i];
            if (val < count - 1) search += "&";
            val++;
          }
        }
      }
      if (parent_search !== "") {
        if (count === 0) search += "key=" + parent_search;
        else search += "&key=" + parent_search;
      }
      const res = yield call(userApi.exportData, data, search);
      if (res.success) {
        swal("Done!", "Parents data exported successfully!", "success");
        yield put({
          type: "HIDE_MODAL",
        });
      }
    } catch (err) {
      errorHandler(err);
    }
  },
  EXPORT_CHILD_DATA: function*({ data }) {
    try {
      let search = "";
      const globalState = require("store").default.getState();
      let filterChild = globalState.user.filterChildSearch;
      let child_search = globalState.user.search_child;
      let val = 0;
      let count = 0;
      for (let i in filterChild) {
        if (filterChild[i] !== "") count++;
      }

      for (let i in filterChild) {
        if (filterChild[i] !== "") {
          if (count === 1) {
            search += i + "=" + filterChild[i];
            break;
          } else {
            search += i + "=" + filterChild[i];
            if (val < count - 1) search += "&";
            val++;
          }
        }
      }
      if (child_search !== "") {
        if (count === 0) search += "key=" + child_search;
        else search += "&key=" + child_search;
      }

      const res = yield call(userApi.exportData, data, search);
      if (res.success) {
        swal("Done!", "Sub profiles data exported successfully!", "success");
        yield put({
          type: "HIDE_MODAL",
        });
      }
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_UPDATE_FREADOS_BY_CHILD_ID: function*({ data }) {
    try {
      const res = yield call(userApi.updateFreadosByChildId, data);
      if (res.success) {
        swal("Done!", "Freados Updated Successfully!", "success");
      }
      yield put({
        type: "REQUEST_CHILD_BY_ID",
        childId: data.child_id,
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_UPDATE_STREAK_BY_CHILD_ID: function*({ data }) {
    try {
      const res = yield call(userApi.updateStreakByChildId, data);
      if (res.success) {
        swal("Done!", "Freados Updated Successfully!", "success");
      }
      yield put({
        type: "REQUEST_CHILD_BY_ID",
        childId: data.child_id,
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REGISTER_USER: function*({ payload }) {
    let parent = null;
    if (payload.add_child) {
      const globalState = require("store").default.getState();
      parent =
        globalState.user.active_parent && globalState.user.active_parent.id;
    }
    let add_child = payload.add_child ? payload.add_child : false;
    try {
      yield put({
        type: "SET_USER_CREATED",
        value: false,
      });
      if (add_child) {
        yield put({
          type: "SET_CREATING_CHILD",
          value: true,
        });
      }

      const res = yield call(userApi.registerUser, payload);

      if (res) {
        if (add_child) {
          yield put({
            type: "SET_CHILD_CREATED",
            value: true,
          });
          yield put({
            type: "SET_CREATING_CHILD",
            value: false,
          });
          yield put({
            type: "REQUEST_CHILDREN_BY_PARENT_ID",
            parentId: parent,
          });
          swal("Done!", "Sub profile Added Successfully!", "success");
          yield put({
            type: "SET_CHILD_CREATED",
            value: false,
          });
        } else {
          swal("Done!", "User Registered Successfully!", "success");
          yield put({
            type: "SET_USER_CREATED",
            value: true,
          });
        }
        yield put({
          type: "SET_SCHOOL",
          school: null,
        });
      }
    } catch (err) {
      // console.log(err)
      errorHandler(err);
      yield put({
        type: "SET_CREATING_CHILD",
        value: false,
      });
    }
  },
  VALIDATE_SCHOOL: function*({ code }) {
    try {
      const res = yield call(userApi.validateSchool, code);
      // console.log(res)
      yield put({
        type: "SET_SCHOOL",
        school: res,
      });
    } catch (err) {}
  },
  VALIDATE_LINK_SCHOOL: function*({ code }) {
    try {
      const res = yield call(userApi.validateSchool, code);
      // console.log(res)
      yield put({
        type: "SET_LINK_SCHOOL",
        school: res,
      });
    } catch (err) {}
  },
  GET_SECTIONS: function*({ data }) {
    // console.log(data)
    try {
      const res = yield call(userApi.getSections, data);
      // console.log(res)
      yield put({
        type: "SET_SECTIONS",
        data: res,
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  GET_BULK_UPDATE_SECTIONS: function*({ data }) {
    // console.log(data)
    try {
      const res = yield call(userApi.getSections, data);
      // console.log(res)
      yield put({
        type: "SET_BULK_UPDATE_SECTIONS",
        data: res,
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  LINK_SCHOOL_CODE: function*({ data }) {
    const child_id = data.child;
    try {
      const res = yield call(userApi.linkSchoolCode, data);
      // console.log(res)
      if (res) {
        swal("Done!", "School linked successfully.", "success");

        yield put({
          type: "REQUEST_CHILD_BY_ID",
          childId: child_id,
        });

        yield put({
          type: "SET_LINK_SCHOOL",
          school: null,
        });
      }
    } catch (err) {
      errorHandler(err);
    }
  },
  UNLINK_SCHOOL_CODE: function*({ data }) {
    // console.log(data)
    let child_id = data.child;
    let { showMessage, ...childData } = data;
    try {
      const res = yield call(userApi.unlinkSchoolCode, childData);
      if (res) {
        if (!showMessage) return;
        swal("Done!", "School unlinked successfully.", "success");
        yield put({
          type: "REQUEST_CHILD_BY_ID",
          childId: child_id,
        });
      }
      // console.log(res)
    } catch (err) {
      errorHandler(err);
    }
  },

  CREATE_BULK_USER: function*({ data }) {
    const gState = require("store").default.getState();
    const grades = gState.activity.grades;
    const academicYears = gState.user.academicYears;

    const errors = [];
    const usersCreated = [];
    let hasValidationErrors = false;

    // Caching for validated schools
    const validatedSchools = {};
    // Caching for school-grade sections
    const schoolGradeSections = {};

    try {
      yield put({
        type: "SET_LOADING",
        value: true,
      });
      for (const payload of data) {
        hasValidationErrors = false;
        let school = null;
        let grade = null;
        let sections = null;
        let section = null;
        let academicYear = null;

        try {
          const validateName = validate(payload.name);
          if (!validateName) {
            errors.push({ payload, error: "Invalid name" });
            hasValidationErrors = true;
            // return;
          }
          if (!validateEmail(payload.email.trim())) {
            errors.push({ payload, error: "Invalid email" });
            hasValidationErrors = true;
            // return;
          }
          if (payload.contact_no.length < 6 && payload.contact_no.length > 15) {
            errors.push({ payload, error: "Invalid contact number" });
            hasValidationErrors = true;
            // return;
          }
          if (!validatePassword(payload.password)) {
            errors.push({ payload, error: "Invalid password" });
            hasValidationErrors = true;
            // return;
          }
          if (!payload.grade) {
            errors.push({ payload, error: "Invalid grade" });
            hasValidationErrors = true;
            // return;
          }
          if (!payload.section) {
            errors.push({ payload, error: "Invalid section" });
            hasValidationErrors = true;
            // return;
          }
          if (!payload.academic_year) {
            errors.push({ payload, error: "Invalid academic year" });
            hasValidationErrors = true;
            // return;
          }

          if (hasValidationErrors) {
            continue;
          }

          academicYear = academicYears.find(
            (year) => year.academic_year === payload.academic_year
          );
          console.log(academicYear);

          if (validatedSchools[payload.schoolCode]) {
            school = validatedSchools[payload.schoolCode];
          } else {
            school = yield call(userApi.validateSchool, payload.schoolCode);
            if (school) validatedSchools[payload.schoolCode] = school;
          }

          if (school) {
            grade = grades.find(
              (grade) =>
                grade.name.toString().toLowerCase() ===
                payload.grade.trim().toLowerCase()
            );
            if (grade) {
              const schoolGradeKey = `${school.id}_${grade.id}`;
              // Check if sections for school & grade are already fetched
              if (schoolGradeSections[schoolGradeKey]) {
                sections = schoolGradeSections[schoolGradeKey];
              } else {
                sections = yield call(userApi.getSections, {
                  grade: grade.id,
                  school: school.id,
                });
                if (sections) schoolGradeSections[schoolGradeKey] = sections;
              }
              if (sections) {
                section = Object.keys(sections).find(
                  (key) => sections[key] === payload.section
                );
                if (section) {
                  payload.school = school.id;
                  payload.grade = grade.id;
                  payload.section = section;
                  payload.email = payload.email.trim();
                  payload.password = payload.password.toString().trim();
                  payload.device_type = "web";
                  payload.academic_year = academicYear && academicYear.id;

                  let res = yield call(userApi.registerUser, payload);
                  if (res) {
                    usersCreated.push(payload);
                  }
                } else {
                  let { device_type, ...remPayload } = payload;
                  errors.push({
                    payload: {
                      ...remPayload,
                      school: school.name,
                      grade: grade.name,
                      section: section ? section : payload.section,
                    },
                    error: "Invalid section",
                  });
                }
              } else {
                let sectionStr = sections
                  ? sections[payload.section]
                  : payload.section;

                let { device_type, ...remPayload } = payload;
                errors.push({
                  payload: {
                    ...remPayload,
                    school: school.name,
                    grade: grade.name,
                    section: sectionStr,
                  },
                  error: "Failed to fetch sections",
                });
              }
            } else {
              let sectionStr = sections
                ? sections[payload.section]
                : payload.section;
              let { device_type, ...remPayload } = payload;
              errors.push({
                payload: {
                  ...remPayload,
                  school: school.name,
                  grade: grade ? grade.name : payload.grade,
                  section: sectionStr,
                },
                error: "Invalid grade",
              });
            }
          } else {
            let sectionStr = sections
              ? sections[payload.section]
              : payload.section;
            let { device_type, ...remPayload } = payload;
            errors.push({
              payload: {
                ...remPayload,
                school: school ? school.name : null,
                grade: grade ? grade.name : payload.grade,
                section: sectionStr,
              },
              error: "Invalid school code",
            });
          }
        } catch (error) {
          let sectionStr = sections[payload.section];
          let { device_type, ...remPayload } = payload;
          errors.push({
            payload: {
              ...remPayload,
              school: school.name,
              grade: grade.name,
              section: sectionStr,
            },
            error: error.error_message,
          });
        }
      }

      yield put({
        type: "SET_LOADING",
        value: false,
      });

      let errorsData = errors.map((err) => {
        return {
          ...err.payload,
          error: err.error,
        };
      });
      if (errors.length > 0) {
        swal(
          "Oops!",
          "There are some errors in the data. A file will get downloaded with all the errors.",
          "error"
        );
        handleDownload(errorsData);
      } else {
        swal("Success", "Users created successfully!", "success");
      }
    } catch (e) {}
  },
  EXPORT_CHILD_DATA_TO_CSV: function*({ data }) {
    try {
      let search = "";
      const globalState = require("store").default.getState();
      let offset = globalState.user.children_offset;
      let filterChild = globalState.user.filterChildSearch;
      let filterChildExtraOptions = globalState.user.filterChildExtraOptions;
      let child_search = globalState.user.search_child;
      let val = 0;
      let extraVal = 0;
      let count = 0;
      let extraCount = 0;
      for (let i in filterChild) {
        if (filterChild[i] !== "") count++;
      }
      for (let i in filterChildExtraOptions) {
        if (filterChildExtraOptions[i] !== "") extraCount++;
      }

      for (let i in filterChild) {
        if (filterChild[i] !== "") {
          if (count === 1) {
            search += i + "=" + filterChild[i];
            break;
          } else {
            search += i + "=" + filterChild[i];
            if (val < count - 1) search += "&";
            val++;
          }
        }
      }

      if (extraCount > 0 && filterChild['section'] !=="") {
        for (let i in filterChildExtraOptions) {
          if (filterChildExtraOptions[i] !== "") {
            search += "&";
            search += i + "=" + filterChildExtraOptions[i];
          }
        }
      }

      if (child_search !== "") {
        if (count === 0) search += "key=" + child_search;
        else search += "&key=" + child_search;
      }

      yield put({
        type: "SET_EXPORT_DATA",
        data: true,
      });

      const res = yield call(userApi.exportDataToCsv, search, data);
      if (res) {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "students.csv");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        yield put({
          type: "SET_EXPORT_DATA",
          data: false,
        });
      }
    } catch (err) {
      errorHandler(err);
    } finally {
    }
  },
  EXPORT_PARENT_DATA_TO_CSV: function*({ data }) {
    try {
      let search = "";
      const globalState = require("store").default.getState();
      //let offset = globalState.user.parents_offset;
      let filterParent = globalState.user.filterParentSearch;
      let parent_search = globalState.user.search_parent;
      let val = 0;
      let count = 0;
      for (let i in filterParent) {
        if (filterParent[i] !== "") count++;
      }

      for (let i in filterParent) {
        if (filterParent[i] !== "") {
          if (count === 1) {
            search += i + "=" + filterParent[i];
            break;
          } else {
            search += i + "=" + filterParent[i];
            if (val < count - 1) search += "&";
            val++;
          }
        }
      }
      if (parent_search !== "") {
        if (count === 0) search += "key=" + parent_search;
        else search += "&key=" + parent_search;
      }

      yield put({
        type: "SET_EXPORT_DATA",
        data: true,
      });

      const res = yield call(userApi.exportDataToCsv, search, data);
      if (res) {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "students.csv");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        yield put({
          type: "SET_EXPORT_DATA",
          data: false,
        });
      }
    } catch (err) {
      errorHandler(err);
    } finally {
    }
  },

  BULK_UPDATE_GRADE_SECTION: function*({ data }) {
    yield put({
      type: "SET_BULK_UPDATING",
      val: true,
    });
    const globalState = require("store").default.getState();
    let childFilter = globalState.user.filterChildSearch;
    let childList = globalState.user.selectedChildren;
    const filteredSearch = Object.fromEntries(
      Object.entries(childFilter).filter(([key, value]) => value !== "")
    );
    try {
      let payload =
        childList && childList.length > 0
          ? {
              new_section: data.section,
              grade: data.grade,
              academicYear: data.academicYear,
              childList,
            }
          : {
              new_section: data.section,
              grade: data.grade,
              academicYear: data.academicYear,
              ...filteredSearch,
            };
      // console.log(payload);
      const res = yield call(userApi.bulkUpdateGradeSection, payload);

      if (res) {
        swal("Done!", "Profiles updated successfully.", "success");

        yield put({
          type: "SET_SELECTED_CHILDREN",
          data: [],
        });

        yield put({
          type: "SET_BULK_UPDATING",
          val: false,
        });

        yield put({
          type: "REQUEST_CHILDREN",
        });
      }
    } catch (err) {
      errorHandler(err);
      yield put({
        type: "SET_BULK_UPDATING",
        val: false,
      });
      yield put({
        type: "SET_SELECTED_CHILDREN",
        data: [],
      });
    }
  },

  GET_CHILD_ACADEMIC_YEARS: function*() {
    try {
      const res = yield call(userApi.getChildAcademicYears);
      // console.log(res)
      yield put({
        type: "SET_CHILD_ACADEMIC_YEARS",
        data: res,
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  GET_CHILD_BASELINE_STATUS : function*({payload}){
    console.log(payload)
    try{
        let res = yield call(userApi.getChildBaselineStatus,...payload)
        console.log(res)
        yield put({
        type: "SET_CHILDREN_BASELINE_STATUS",
        data: res,
      });
    }catch(err){
      errorHandler(err)
    }
  }
});

function* watchRequestChildren() {
  yield takeLatest("REQUEST_CHILDREN", sagas.REQUEST_CHILDREN);
}

export default sagas;

export const watcherSagas = [watchRequestChildren];
