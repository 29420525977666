export default {
  parent_list: [],
  active_parent: null,
  children_list: [],
  active_child: null,
  search_parent: "",
  search_child: "",
  export_parent_data: "",
  export_child_data: "",
  filterParentSearch: {
    user__is_active: "",
    user__is_contact_no_verified: "",
  },
  filterChildSearch: {
    user__is_active: "",
    grade__name__icontains: "",
    school__iexact: "",
    section: "",
    parent__user__is_teacher: "",
    academic_year__id:""
  },
  filterChildExtraOptions: {
    school_code:"",
    section_name:""
  },
  fetch_parent: false,
  fetch_child: false,
  parents_offset: 0,
  children_offset: 0,
  grades: [],
  gradeList: [],
  school: null,
  link_school: null,
  sectionsList: [],
  user_created: false,
  sections: [],
  loading: false,
  exportData: false,
  bulkUpdating: false,
  bulkUpdateSections: [],
  bulkUpdateSectionsList: [],
  childCreated: false,
  creatingChild: false,
  selectedChildren:[],
  academicYears:[],
  childrenBaselineStatus:null
};
