import React, { useEffect } from 'react'
import './BulkUpdateGradeSection.css'
import Select from 'react-select'
import { connectStore } from 'redux-box'
import { module as ui } from "@st/ui";
import { module as user } from "@st/user";
import { module as activity } from "@st/activity";
import { module as inviteCode } from "@st/inviteCode";
import { module as onboard } from "@st/onboard";
import { ThemeButton } from '../../../static/styled/custom.styled';
import { useState } from 'react';
import swal from 'sweetalert2';

const BulkUpdateGradeSection = (props) => {
  const [grade, setGrade] = useState('')
  const [section, setSection] = useState('')
  const [academicYear, setAcademicYear] = useState('')
  const [allGrades, setAllGrades] = useState([])
  const [allSections, setAllSections] = useState([])
  const [school, setSchool] = useState(null)

  useEffect(() => {

    if (props.activity.gradeList.length > 0) {
      setAllGrades(props.activity.gradeList);
    }
  }, [props.activity.gradeList]);

  useEffect(() => {
    if (props.user.filterChildSearch && props.user.filterChildSearch.school__iexact && props.user.filterChildSearch.school__iexact.length > 0) {
      let school = props.inviteCode.adminSchoolList && props.inviteCode.adminSchoolList.find((school) => school.name === props.user.filterChildSearch.school__iexact)
      setSchool(school)
    }

    if (props.onboard && props.onboard.user && props.onboard.user.school){
      let school = props.inviteCode.adminSchoolList && props.inviteCode.adminSchoolList.find((school)=>school.id===props.onboard.user.school)
      setSchool(school)
    }
  }, [])

  const changeGrade = (selectedGrade) => {
    setAllSections([])
    setGrade(selectedGrade)
    if (school) {
      props.user.getBulkUpdateSections({
        school: school.id,
        grade: selectedGrade.id
      })
    }
  }

  useEffect(() => {
    if (props.user.bulkUpdateSectionsList && props.user.bulkUpdateSectionsList.length > 0) {
      setAllSections(props.user.bulkUpdateSectionsList);
    }
  }, [props.user.bulkUpdateSectionsList]);


  const handleUpdate = () => {

    if (section && academicYear) {
      props.user.bulkUpdateGradeSection({
        grade: grade.id,
        section: section.value,
        academicYear : academicYear.value
      });
    }else{
      swal("Please select section and academic year!",'error')
    }

    props.ui.hideModal()
  }

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      position: "relative"
    }),
  };

  return (
    <div className='update_grade_section_container'>
      <div className='update_grade_section_body'>
        <div className='update_grade_section_title'>Update Grade</div>
        <Select
          className="dropdown-container"
          value={grade}
          onChange={changeGrade}
          options={allGrades && allGrades.length >= 1 && allGrades}
          placeholder="Select Grade"
          styles={customStyles}
        />
        {
          school &&
          <Select
            className="dropdown-container"
            value={section}
            onChange={(selectedSection) =>
              setSection(selectedSection)
            }
            options={allSections && allSections}
            placeholder="Select Section"
            styles={customStyles}
          />
        }
          <Select
            className="dropdown-container"
            value={academicYear}
            onChange={(selectedYear) =>
              setAcademicYear(selectedYear)
            }
            options={props.user.academicYears && props.user.academicYears.length > 0 && props.user.academicYears.map((year)=>({label:year.academic_year,value:year.id}))}
            placeholder="Select Academic Year"
            styles={customStyles}
          />

      </div>

      <div className='update_grade_section_footer'>
        <ThemeButton style={{ width: '100%' }} primary onClick={() => handleUpdate()}>Update</ThemeButton>
      </div>

    </div>
  )
}

export default connectStore({
  ui, user, activity, inviteCode,onboard
})(BulkUpdateGradeSection)